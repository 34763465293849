import { UxModalServiceResult } from '@aurelia-ux/modal';
import { inject } from 'aurelia-framework';
import { Global } from '../../global';
import { errorify, notify } from 'aurelia-resources';
import { jsonify } from 'aurelia-deco';
import { getLogger } from 'aurelia-logging';
const log = getLogger('member-dialog');

export interface CheckBoxRoles {
  name: string;
  value: boolean;
}

@inject(Global)
export class  MemberDialog {

  public mode: 'create' | 'edit' = 'create';
  public member: {userId: string, name: string, roles: string[]};
  public roles: string[] = [];
  public superAdminRole: string = 'manager';

  private cbRoles: CheckBoxRoles[] = [];

  constructor(private global: Global) {
  }

  public activate(params: any) {
    if (params.member) {
      this.member = params.member;
      if (!Array.isArray(this.member.roles)) {
        this.member.roles = [];
      }
      this.mode = 'edit';
    } else {
      this.member = {userId: '', name: '', roles: []}
      this.mode = 'create';
    }
    if (params.roles && Array.isArray(params.roles)) {
      this.roles = params.roles;
    } else {
      params.roles = ['manager'];
    }
    if (params.superAdminRole && typeof params.superAdminRole === 'string') {
      this.superAdminRole = params.superAdminRole;
    } else {
      this.superAdminRole = 'manager';
    }

    if (this.member && this.roles) {
      for (const role of this.roles) {
        if ( this.member.roles && this.member.roles.includes(role)) {
          this.cbRoles.push({name: role, value: true});
        } else {
          this.cbRoles.push({name: role, value: false});
        }
      }
    }
  }

  private checkRoles(){
    this.member.roles = [];
    for (const r of this.cbRoles) {
      if (r.value) {
        this.member.roles.push(r.name);
      } 
    }
    console.log(this.member.roles);
  }

  public async canDeactivate(result: UxModalServiceResult) {
    if (result.wasCancelled) {
      return true;
    }
    if (result.output === 'remove') {
      try {
        // const members = await this.global.shops.api.delete(`/shop/admin/${this.shopId}/members/${this.member.userId}`).then(jsonify);
        // result.output = members;
        // notify('Member removed');
        return true;
      } catch (error) {
        errorify(error);
      }
    }
    if (typeof this.member === 'object' && typeof this.member.userId === 'string' && Array.isArray(this.member.roles)) {
      try {
        if (this.mode === 'create') {
          result.output = this.member;
          notify('Member added');
        } else {
          result.output = this.member;
          // notify('Member edited');
        }
      } catch (error) {
        errorify(error);
      }
    }
  }
  
}

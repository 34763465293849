import { inject} from 'aurelia-framework';
import { Global } from 'global';
import { getLogger } from 'aurelia-logging';
import { Link } from 'app';

const log = getLogger('account');

@inject(Global)
export class Home {    

  public link: Link = undefined;

  constructor(private global: Global) {
  }

  
  public activate() {
    console.log('Start home');
  }

 

}

import { UxModalServiceResult } from '@aurelia-ux/modal';
import { inject } from 'aurelia-framework';
import { Global } from '../../global';
import { errorify, notify } from 'aurelia-resources';
import { DynamicDataModel, errorHandler } from 'aurelia-deco';
import { getLogger } from 'aurelia-logging';
const log = getLogger('member-dialog');
import readXlsxFile from 'read-excel-file';

export interface Annotation {
  refId?: string;
  title?: string;
  description?: string;
  projectId?: string;
  type?: 'annotation' | 'point';
  tag?: string;
  code?: string;
  positionX?: number;
  positionY?: number;
  positionZ?: number;
  image?: string[];
  externalLink?: string;
  km?: number;
}

export interface ReferenceColumn {
  name: string;
  matching: number;
  example: string;
}


export interface GeometryPoint {
  type: 'LineString' | 'Point';
  coordinates: number[];
}

export interface GeometryLineString {
  type: string;
  coordinates: number[][];
}

export interface Xdata {
  appName: string;
  data: any[];
}

export interface Properties {
  id: string;
  km: number;
  categories?: string[];
  dxfCode?: string;
  layer?: string;
  space?: string;
  color?: string;
  rotation?: number;
  scale?: string;
  lineType?: string;
  lineWeight?: string;
  text?: string;
  blocName?: string;
  xdatas?: Xdata[];
  attributes?: any;
  dynamics?: any;
}

export interface Feature {
  type: string;
  geometry: GeometryPoint | GeometryLineString;
  properties: Properties;
}

export interface FeatureCollection {
  type: string;
  features: Feature[];
}



@inject(Global)
export class ImportData {
  
  public projectId: string = undefined;
  public dataType: 'annotation' | 'axis' = 'annotation';
  private validExtention: string = 'xlsx';

  private inputbox: HTMLElement;
  private selectedFiles: any;
  private selectedFile: string = "";
  private progress: number = 0;
  private validatedCount: number = 0;
  private errorCount: number = 0;
  private inProgress: string = "Saving in progress";
  private progressResultMessage: string = undefined;
  private newAnnotation: Annotation[] = [];
  private labels: string[] = [];
  private reflabels: ReferenceColumn[] = [
    {name: 'refId', matching: -1, example: ''},
    {name: 'title', matching: -1, example: ''},
    {name: 'description', matching: -1, example: ''},
    {name: 'type', matching: -1, example: ''},
    {name: 'tag', matching: -1, example: ''},
    {name: 'code', matching: -1, example: ''},
    {name: 'positionX', matching: -1, example: ''},
    {name: 'positionY', matching: -1, example: ''},
    {name: 'positionZ', matching: -1, example: ''},
    {name: 'km', matching: -1, example: ''},
  ];

  private activeColumnEdition: boolean = false;
  private activeSaveApi: boolean = false;
  private activeInProgress: boolean = false;
  private activeResult: boolean = false;
  private tenFirstRows = [];
  private allRows = [];
  private finishReading: boolean = false;
  private pointExamples: Annotation[] = [];

  private activeSaveAxis: boolean = false;
  private axis: Feature[] = [];

  constructor(private global: Global) {
  }

  public activate(params: any) {
      if (params.projectId) {
          this.projectId = params.projectId;
      }
      if (params.dataType) {
          this.dataType = params.dataType;
      }
      this.setParam();
  }

  public setParam(){
    if (this.dataType == 'annotation') {
      this.selectedFile = "Select Excel file (xlsx)"
      this.validExtention = "xlsx";
    } else if (this.dataType == 'axis') {
      this.selectedFile = "Select Axis file (geojson)"
      this.validExtention = "geojson";
    }
  }

  public async canDeactivate(result: UxModalServiceResult) {
    if (result.wasCancelled) {
      return true;
    }
    if (result.output === 'remove') {
      try {
        // const members = await this.global.shops.api.delete(`/shop/admin/${this.shopId}/members/${this.member.userId}`).then(jsonify);
        // result.output = members;
        // notify('Member removed');
        return true;
      } catch (error) {
        errorify(error);
      }
    }
    if (true) {
      try {
        result.output = true;
      } catch (error) {
        errorify(error);
      }
    }
  }




  // Import from file
  private selectFile() {
    this.inputbox.click();
  }

  private updateFileName() {
    this.selectedFile = this.selectedFiles[0].name;

    let extFile = this.selectedFile.substring(this.selectedFile.length - this.validExtention.length - 1, this.selectedFile.length);

    if (extFile.toLowerCase() != "." + this.validExtention) {
      this.selectedFile = 'Fichier incompatible';
    }
    else {
      this.loadFile();
    }
  }

  private async loadFile() {

    let file = this.selectedFiles[0];
    let columnNb: number = 0;
    let rowNb: number = 6;

    if (this.dataType == 'annotation') {
      readXlsxFile(file).then((rows) => {
        // `rows` is an array of rows each row being an array of cells.
        if (rows) {
          if (rows.length < 5) rowNb = rows.length;
          for (let index = 0; index < rowNb; index++) {
            const row = rows[index];
            
            // Max 100 columns
            if (index == 0) {
              for (let c = 0; c < 100; c++) {
                if (row && row[c] == '') break;
                if (row && row[c]) {
                  this.labels.push(row[c].toString());
                  columnNb = c;
                }
              }
            } else {
              this.tenFirstRows.push(row);
            }
  
  
          }
        }
      }).then(() => {
        this.activeColumnEdition = true;
      })

    } else if (this.dataType == 'axis') {
      let reader = new FileReader();
      reader.onload = (event) => {
        console.log('event', event);
        let trans = JSON.parse((event as any).target.result);
        this.parseLoadedFile(trans);
      };
      reader.readAsText(file, 'UTF-8');
    }

  }

  parseLoadedFile(features : FeatureCollection) {
   
    // Search KM + Cartouche
    if (features && features.features) {
      for (let feature of features.features) {
          this.axis.push(feature);
      } 
    }
    setTimeout( () => { 
      this.inProgress = "Successfully completed"; 
      this.activeSaveAxis = true;
      this.activeSaveApi = true;
   }, 500 );

  }




  private async loadAllDataFile() {
    let file = this.selectedFiles[0];
    await readXlsxFile(file).then((rows) => {
      // `rows` is an array of rows each row being an array of cells.
      if (rows) {
        for (let index = 1; index < rows.length; index++) {
          this.allRows.push(rows[index]);
        }
      }
    }).then(() => {
      this.finishReading = true;
    })
  }

  private currentColumnChanged(index:number, selectedColumn: number) {
    this.reflabels[index].matching = Number(selectedColumn);
    console.log('reflabels', this.reflabels);
    if (this.tenFirstRows) {
      for (const row of this.tenFirstRows) {
        for (const reflabel of this.reflabels) {
          if (reflabel.matching != -1) reflabel.example = this.tenFirstRows[0][reflabel.matching];
        }
      }
    }
  }

  private saveLabelMatching(){
    this.pointExamples = this.convertRowAnnotation(this.tenFirstRows);
    this.activeColumnEdition = false;
    this.activeSaveApi = true;
  }

  private convertRowAnnotation(rows: any[]){
    let annotations: Annotation[] = [];
    for (const row of rows) {
      let p = {} as Annotation;
      for (const reflabel of this.reflabels) {
        if (reflabel.name == 'refId' && reflabel.matching != -1) p.refId = row[reflabel.matching];
        if (reflabel.name == 'title' && reflabel.matching != -1) p.title = row[reflabel.matching];
        if (reflabel.name == 'description' && reflabel.matching != -1) p.description = row[reflabel.matching]
        if (reflabel.name == 'type' && reflabel.matching != -1) p.type = row[reflabel.matching];
        if (reflabel.name == 'tag' && reflabel.matching != -1) p.tag = row[reflabel.matching];
        if (reflabel.name == 'code' && reflabel.matching != -1) p.code = row[reflabel.matching];
        if (reflabel.name == 'positionX' && reflabel.matching != -1) p.positionX = row[reflabel.matching];
        if (reflabel.name == 'positionY' && reflabel.matching != -1) p.positionY = row[reflabel.matching];
        if (reflabel.name == 'positionZ' && reflabel.matching != -1) p.positionZ = row[reflabel.matching];
        if (reflabel.name == 'km' && reflabel.matching != -1) p.km = row[reflabel.matching];
      }
      annotations.push(p);
    }
    return annotations;
  }
  
  private async sendApi() {
    
    // Send
    this.activeInProgress = true;

    if (this.dataType == 'annotation') {
      await this.loadAllDataFile();
      let annotations =  this.convertRowAnnotation(this.allRows);
      let i: number = 0;
      for (const annotation of annotations) {
        this.progress = Math.round((i / annotations.length) * 100);
        i++;
        await this.addNewAnnotation(annotation, false);
      }
      this.progress = 100;
      this.progressResultMessage = annotations.length - this.errorCount + ' new points are saved (' + this.errorCount + ' errors)';
    } else if (this.dataType == 'axis') {
      let i: number = 0;
      for (const axi of this.axis) {
        this.progress = Math.round((i / this.axis.length) * 100);
        i++;
        await this.addNewAxi(axi, false);
      }
      this.progress = 100;
      this.progressResultMessage = this.axis.length - this.errorCount + ' new axis are saved (' + this.errorCount + ' errors)';
    } 



    notify(this.progressResultMessage, { type: 'success' });
    this.selectedFiles = [];
    this.setParam();
    this.activeInProgress = false;
    this.activeResult = true;

  }

  public async addNewAnnotation(addAnnotation: Annotation, notif: boolean) {
    let annotation = new DynamicDataModel('annotation');
    let check: boolean = true;

    if (!addAnnotation.positionX || addAnnotation.positionX == 0) check = false;
    if (!addAnnotation.positionY || addAnnotation.positionY == 0) check = false;
    if (!addAnnotation.positionZ || addAnnotation.positionZ == 0) check = false;

    if (check){
      if (!addAnnotation.type) addAnnotation.type = 'point';
  
      // annotation.set('refId', addAnnotation.refId);
      if (addAnnotation.title) annotation.set('title', addAnnotation.title);
      if (addAnnotation.description) annotation.set('description', addAnnotation.description);
      annotation.set('projectId', this.projectId);
      if (addAnnotation.type) annotation.set('type', addAnnotation.type);
      if (addAnnotation.tag) annotation.set('tag', addAnnotation.tag);
      if (addAnnotation.code) annotation.set('code', addAnnotation.code);
      if (addAnnotation.positionX) annotation.set('positionX', addAnnotation.positionX);
      if (addAnnotation.positionY) annotation.set('positionY', addAnnotation.positionY);
      if (addAnnotation.positionZ) annotation.set('positionZ', addAnnotation.positionZ);
      // annotation.set('image', addAnnotation.image);
      // annotation.set('externalLink', addAnnotation.externalLink);
      if (addAnnotation.km) annotation.set('km', addAnnotation.km);
  
      const validationResult = await annotation.validationController.validate();
      if (!validationResult.valid) {
        const firstInvalid = validationResult.results.find(r => r.valid === false);
        throw new Error(firstInvalid.message || 'Invalid form');
      }
      if (annotation.id) {
      } else {
        await annotation.save().then(() => {
          if (notif) notify('New annotation is saved', { type: 'success' });
        }).catch(errorify);
      }
    } else {
      this.errorCount++;
    }
  }

  public async addNewAxi(addAxi: Feature, notif: boolean) {
    let axi = new DynamicDataModel('axis');

      if (addAxi.type) axi.set('type', addAxi.type);
      if (addAxi.geometry) axi.set('geometry', addAxi.geometry);
      if (addAxi.properties) axi.set('properties', addAxi.properties);
  
      const validationResult = await axi.validationController.validate();
      if (!validationResult.valid) {
        const firstInvalid = validationResult.results.find(r => r.valid === false);
        throw new Error(firstInvalid.message || 'Invalid form');
      }
      if (axi.id) {
      } else {
        await axi.save().then(() => {
          if (notif) notify('New axis is saved', { type: 'success' });
        }).catch(errorify);
      }
  }


  public async saveEditAnnotation(addAnnotation: Annotation, notif: boolean) {
    // const validationResult = await this.editLink.validationController.validate();
    // if (!validationResult.valid) {
    //   const firstInvalid = validationResult.results.find(r => r.valid === false);
    //   throw new Error(firstInvalid.message || 'Invalid form');
    // }
    // if (this.editLink.id) {
    //   await this.editLink.updateProperties('', ['urlId', 'urlTarget', 'urlType', 'name', 'buildingName', 'roomNb']);
    // } else {
    //   this.editLink.save().then(() => {
    //     notify('New Link is saved', { type: 'success' });
    //   }).catch(errorify);
    // }

    // this.editLink = {} as Link;
    // this.getLinks();
  }



}
